<template>
  <div :is="layout">
    <v-card class="mt-4">
      <v-container fluid class="pa-4">
            <v-text-field
                v-model="form_info.name"
                style="width: 600px"
                label="算法名称"
            ></v-text-field>
          <div class="editor-container">
            <editor ref="edit" @language-change="changeMode" v-model="form_info.code"></editor>
          </div>
          <v-btn @click="submit" class="float-right mt-3 primary">确认</v-btn>
          <div style="clear:both"></div>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import Editor from '@/components/Edit'
import {algorithm_create, algorithm_info, algorithm_edit} from "@/api/algorithm";

export default {
  name: 'algorithm-create',
  props: ['id'],
  data: () => ({
    layout: 'div',
    edit_mode: false,
    form_info: {
      code: '',
      name: '',
      mode: 'Python',
    }

  }),
  methods: {
    changeMode(e) {
      this.form_info.mode = e
    },
    submit() {
      if (this.edit_mode) {
        algorithm_edit(this.form_info).then(res => {
          console.log(res)
        })
      }else {
        algorithm_create(this.form_info).then(res => {
          console.log(res)
        })
      }
    },
    changeCode(e) {
      this.form_info.code = e
    }
  },
  mounted() {
    this.layout=this.$route.meta.use_layout
    if (this.$route.name === 'algorithmEdit') {
      this.edit_mode = true
      algorithm_info(this.id).then(res => {
        this.$refs.edit.coder.setValue(res.code)
        this.form_info.id = res.id
        this.form_info.name = res.name;
        this.form_info.made = res.mode;
        this.form_info.code = res.code;
      })
    }

    console.log(this.id)
  },
  components: {
    Editor
  }
}
</script>

<style lang="stylus" scoped>
.editor-container
  position relative

</style>